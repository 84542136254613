<script>
    

import { bank_list } from "@/api/my";
import { account_balance } from "@/api/my";
import { cash_withdrawal_application } from "@/api/my";
import now_cash from "@/views/my/cash_withdrawal/now_cash";

export default {
    name:'Withdraw',
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
        now_cash,
    },
    data() {
        return {
            setAt:true,         //防止重复提交
            button_style: [
                { value: 100, myactive: true },
                { value: 500, myactive: true },
                { value: 1000, myactive: true },
                { value: 5000, myactive: true },
                { value: 10000, myactive: true }
            ],
            password: "",
            bank_num: "",
            bank_name: "",
            pay_name: "",
            account_balance: "",
            cash_amount: "",
            service_charge: "",
            accountable_amount: "",
            subbranch: "",
            id: "",

            header: {
                mainTitle: getWord(['withdraw3', 'information']),
            },
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        //银行卡信息
        search_bank() {
            bank_list().then(result => {
                if (result.data.code == "SUCCESS") {
                    if (!result.data.result.length == 0) {
                        this.pay_name = result.data.result[0].pay_name;
                        this.bank_num = result.data.result[0].bank_num;
                        this.bank_num = this.bank_num.replace(/\s*/g, "");
                        this.subbranch = result.data.result[0].subbranch;
                        this.bank_name = result.data.result[0].bank_name;
                        this.id = result.data.result[0].id;
                    }
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
            account_balance(JSON.parse(localStorage.getItem("user")).token).then(
                result => {
                    if (result.data.code == "SUCCESS") {
                        this.account_balance = result.data.result.money;
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                }
            );
        },

        handleClick(index, value) {
            //提现值自动填入
            this.cash_amount = value;
            for (var i = 0; i < 5; i++) {
                this.button_style[i].myactive = true;
            }
            this.button_style[index].myactive = !this.button_style[index].myactive;
        },
        mycommit() {
            if(this.setAt != true){
                    return;
            }else{
                    this.setAt = false;
            }
            //提交提现
            if (Number(this.cash_amount)>Number(this.account_balance)) {
                app.Mint.Toast(getWord('withdraw_rules4'));
            }
            this.cash_amount=parseFloat(this.cash_amount).toFixed(2);
            cash_withdrawal_application(
                this.cash_amount,
                this.password,
                this.id
            ).then(result => {
                if (result.data.code == "SUCCESS") {
                    this.$router.push({ path: "/my/cash_withdrawal/presentation_copy" });
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
            this.setAt = true;
        },
        cash_withdrawal_application() {
            var password_reg = /^[0-9]{6,8}$/;
            if (!this.password.match(password_reg)) {
                app.Mint.Toast(getWord('password_rules17',{'$1':6,'$2':8}));
            } else if (parseFloat(this.cash_amount) > parseFloat(this.account_balance)) {
                app.Mint.Toast(getWord('withdraw_rules4'));
            } else {
                this.$refs.now_cash.bool = true;
                this.$refs.now_cash.cash_amount = this.cash_amount;
                this.$refs.now_cash.service_charge = this.service_charge;
                this.$refs.now_cash.accountable_amount = this.accountable_amount;
            }
        }
    },
    mounted() {
        this.CustomScroll(this.$refs.paymentInformation.id);
        this.search_bank();
    },
    watch: {
        //监视填写的提现金额
        cash_amount: function(Value, oldValue) {
            var expense = this.$route.query.expense;
            var outExpense = this.$route.query.outExpense;            
            this.service_charge = parseInt(
                (parseInt(expense.trim()) / 100) * this.cash_amount +
                    parseInt(outExpense.trim())
            );
            this.accountable_amount = this.cash_amount - this.service_charge;
            if (this._TEMPLATE==='template-3') {
                this.service_charge=this.service_charge;
            }
            
        }
    }
};
</script>
<template>
    <div id="payment-information" ref="paymentInformation" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-3'">
            <Header :mainTitle="header.mainTitle" :backButton=false />
            <h4>{{ getWord(['withdraw', 'account', 'question']) }}</h4>
            <div class="form-body">
                <div class="fieldset clearfix">
                    <span>{{ getWord('bank_name2') }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="bank_name">
                </div>
                <div class="fieldset clearfix">
                    <span>{{ getWord(['withdraw', 'account']) }}</span>
                    <input @blur="_blured($event)" text="text" disabled="true" v-model="pay_name" />
                </div>
                <div class="fieldset clearfix">
                    <span>{{ getWord(['withdraw', 'cardnumber']) }}</span>
                    <input @blur="_blured($event)" text="text" disabled="true" v-model="bank_num" />
                </div>
            </div>
            <h4>{{ getWord(['select', 'withdraw', 'amount_of_betting']) }}</h4>
            <div class="buttons">
                <a v-for="(item,index) in button_style" :class="{active:!button_style[index].myactive}" @click="handleClick(index,item.value)">￥{{item.value}}</a>
            </div>
            <div class="form-body">
                <div class="fieldset clearfix text-right">
                    <span>{{ getWord('account_balance3') }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="account_balance">
                </div>
                <div class="fieldset clearfix text-right">
                    <span>{{ getWord(['withdraw', 'amount_of_betting']) }}</span>
                    <input @blur="_blured($event)" :placeholder="getWord('fill_withdraw')+'(100~10000)'" v-model="cash_amount" />
                </div>
                <div class="fieldset clearfix text-right">
                    <span>{{ getWord(['withdraw', 'password']) }}</span>
                    <input @blur="_blured($event)" type="password" :placeholder="getWord(['fill', 'withdraw', 'password'])" v-model="password" />
                </div>
                <div class="fieldset clearfix text-right notice">
                    <span>{{ getWord('fee') }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="service_charge" />
                </div>
                <div class="fieldset clearfix text-right">
                    <span>{{ getWord('available_withdraw') }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="accountable_amount" />
                </div>
            </div>
        </template>
        <now_cash ref="now_cash"></now_cash>
        <div class="myposition"></div>
        <div class="main">
            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <mt-header :title="getWord('withdraw')" style="height:0.88rem;font-size: 0.35rem;">
                <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
            </mt-header>
            <span class="main_span">{{ getWord(['withdraw', 'account', 'question']) }}</span>
            <div class="count_message">
                <div>
                    <span class="main_footer_span">{{ getWord('bank_name2') }}:</span>
                        <input class="main_footer_input" :placeholder="getWord('fill_bank_name')" type="text" v-model="bank_name" disabled="disabled">
                </div>
                <div>
                    <span class="main_footer_span">{{ getWord(['withdraw', 'account']) }}:</span>
                        <input class="main_footer_input" type="text" v-model="pay_name" :placeholder="getWord('fill, username4')" disabled="disabled">
                </div>
                <div>
                    <span class="main_footer_span" style="border-bottom: 0">{{ getWord('bannkcard2') }}:</span>
                        <input class="main_footer_input" type="text" :placeholder="getWord('fill_bankcard2')" v-model="bank_num" disabled="disabled" style="border-bottom: 0">
                </div>
            </div>
            <span class="main_span">{{ getWord(['select', 'withdraw', 'amount_of_betting']) }}</span>
            <div class="main_button">        
                <template v-for="(item,index) in button_style">
                        <button class="main_button_mt-button" v-if="button_style[index].myactive" @click="handleClick(index,item.value)">{{item.value}}</button>
                        <button class="main_button_mt-button1" v-if="!button_style[index].myactive" @click="handleClick(index,item.value)">{{item.value}}</button>
                </template>
            </div>
            <div class="count_message">
                <div>
                    <span class="main_footer_span">{{ getWord('account_balance3') }}:</span>
                        <input class="main_footer_input" type="text" v-model="account_balance" disabled="disabled" style="text-align: right">
                </div>
                <div>
                    <span class="main_footer_span">{{ getWord(['withdraw', 'amount_of_betting']) }}:</span>
                        <input class="main_footer_input" type="number" :placeholder="getWord('fill_withdraw')" v-model="cash_amount" style="text-align: right">
                </div>
                <div>
                    <span class="main_footer_span">{{ getWord(['withdraw', 'password']) }}:</span>
                        <input maxlength="8" class="main_footer_input" type="password" :placeholder="getWord(['fill', 'withdraw', 'password'])" v-model="password" style="text-align: right">
                </div>
                <div>
                    <span class="main_footer_span">{{ getWord('fee') }}:</span>
                        <input class="main_footer_input" type="text" v-model="service_charge" disabled="disabled" style="text-align: right;color:red">
                </div>
                <div>
                    <span class="main_footer_span" style="width:24%;border-bottom: 0">{{ getWord('available_withdraw') }}:</span>
                        <input class="main_footer_input" type="text" v-model="accountable_amount" disabled="disabled" style="width:64%;text-align: right;border-bottom: 0">
                </div>
            </div>
            </template>
            
            
            <span class="main_spantext">{{ getWord('tips') }}：</span>
            <span class="main_spantext">1.{{ getWord('withdraw_rules15') }}；</span>
            <span class="main_spantext">2.{{ getWord('withdraw_rules16',{'$1':1,'$2':30}) }}。</span>
            <mt-button class="foot_button" type="primary" @click="cash_withdrawal_application()">{{ getWord('submit') }}</mt-button>
        </div>
    </div>
</template>
<style scoped lang='scss' style="text/css">
#payment-information{
    overflow: auto;
    padding-bottom: .5rem;

    input {

        &:disabled {
            -webkit-text-fill-color: #333333;
            opacity: 1;
            color:#333333;
        }
    }

    &.template-3 {

        h4 {
            font-size: .3rem;
            font-weight: normal;
            color: #B0B0B0;
            margin: .25rem;
        }

        .form-body {
            padding: 0 .25rem;
            background-color: #ffffff;

            .fieldset {
                padding: .2rem 0;
                border-top: 1px solid #eeeeee;
                display: flex;
                align-items: center;

                &.notice {

                    input {

                        &:disabled {
                            color: #EC2829;
                            -webkit-text-fill-color: #EC2829;
                            opacity: 1;
                        }
                    }
                }

                &.text-right {

                    input {
                        text-align: right;
                    }
                }

                &.select {

                    input {

                        &::placeholder {
                            color: #333333;
                        }
                    }
                }

                &:first-child {
                    border-top: 0;
                }

                span {
                    flex-basis: 2rem;
                    font-size: .3rem;
                    color: #333333;
                }

                input {
                    border: 0;
                    box-sizing: border-box;
                    padding: 0 .25rem;
                    font-size: .3rem;
                    height: .48rem;
                    vertical-align: middle;
                    width: 100%;

                    &:disabled {
                        background-color: transparent;
                    }

                    &:focus {
                        background-color: #FAFFBD;
                    }

                    &::placeholder {
                        color: #B0B0B0;
                    }
                }

                .iconfont {
                    font-size: .4rem;
                    text-align: center;
                    display: inline-block;
                    color: #B0B0B0;
                    vertical-align: middle;

                    &.icon-back {
                        position: absolute;
                        transform: rotateZ(180deg);
                        right: .15rem;
                        opacity: .4;
                    }
                }
            }
        }

        .buttons {
            margin: .25rem;
            display: flex;
            justify-content: space-evenly;

            a {
                border: 1px solid #CCCCCC;
                padding: .15rem;
                border-radius: 3px;
                background-color: #ffffff;
                box-shadow: 0px 0.5px 1px 0px rgba(0,0,0,.1);
                font-size: .28rem;

                &.active {
                    color:#EC2829;
                    border-color:#EC2829;
                }
            }
        }

        .main {

            .main_spantext {
                float: none;
                display: block;
                font-size: .28rem;
                margin: .25rem;
            }

            .foot_button {
                font-size: .34rem;
                float: none;
                display: block;
                width: 84%;
                height: auto;
                margin:.5rem auto;
                background-color: #EC2829;
                padding: .2rem 0;
            }
        }
    }

.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.main_span {
    float: left;
    width: 90%;
    height: 0.3rem;
    font-size: 0.3rem;
    color: rgba(153, 153, 153, 1);
    margin: 0.3rem 0 0.1rem 0.2rem;
}

.count_message {
    float: left;
    width: 100%;
    margin-top: 0.2rem;
    background-color: white;
}

.main_footer_span {
    float: left;
    width: 18%;
    height: 0.9rem;
    font-size: 0.3rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    border: 0;
    background-color: white;
    border-bottom: 1px solid rgba(187, 187, 187, 1);
    text-align: left;
    line-height: 0.9rem;
    margin-left: 2%;
}

.main_footer_input {
    float: left;
    width: 70%;
    height: 0.9rem;
    border: 0;
    background-color: white;
    border-bottom: 1px solid rgba(187, 187, 187, 1);
    font-size: 0.3rem;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: left;
    padding-left: 5%;
    padding-right: 5%;
    outline: none;
}

.main_button {
    float: left;
    width: 100%;
    margin-top: 0.2rem;
}

.main_button_mt-button {
    float: left;
    width: 18%;
    height: 0.68rem;
    margin-left: 0.13rem;
    border-radius: 0.1rem;
    background-color: transparent;
    outline: none;
    border: 1px solid rgba(153, 153, 153, 1);
}

.main_button_mt-button1 {
    float: left;
    width: 18%;
    height: 0.68rem;
    margin-left: 0.13rem;
    border-radius: 0.1rem;
    color: rgba(71, 150, 252, 1);
    background-color: transparent;
    border: 1px solid rgba(71, 150, 252, 1);
    outline: none;
}

.main_spantext {
    float: left;
    width: 90%;
    height: 0.2rem;
    font-size: 0.2rem;
    color: rgba(153, 153, 153, 1);
    margin: 0.2rem 0 0 0.4rem;
}

.foot_button {
    float: left;
    width: 6.71rem;
    height: 0.98rem;
    margin: 0.3rem 0 0 0.3rem;
}
            }
</style>