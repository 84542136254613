<script>
export default {
    data() {
        return {
            bool: false,
            cash_amount: "",
            service_charge: "",
            accountable_amount: ""
        };
    },
    components: {
        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
    },
    methods: {
        commit() {
            this.$parent.mycommit();
            this.bool = false;
        },
        canal() {
            this.bool = false;
        }
    },
    mounted() {},
    watch: {}
};
</script>
<template>
    <section id="withdraw-confirm" v-if="bool" :class="_TEMPLATE">
        <div class="myposition"></div>
        <div class="main">
            <mt-header :title="getWord('withdraw4')" style=""></mt-header>
            <div class="this_withdrawal">
                <div class="span_1">{{ getWord(['withdraw', 'amount_of_betting']) }}</div>
                <div class="span_1">{{cash_amount}}</div>
            </div>
            <div class="this_withdrawal">
                <div class="span_1">{{ getWord('exclude_fee') }}</div>
                <div class="span_1" style="color:red">{{_TEMPLATE==='template-3'?'':'-'}}{{service_charge}}</div>
            </div>
            <div class="this_withdrawal">
                <div class="span_1">{{ getWord('available_withdraw') }}</div>
                <div class="span_1">{{accountable_amount}}</div>
            </div>
            <div class="buttons">
                <mt-button class="foot_button" type="primary" @click="commit()">
                    {{_TEMPLATE==='template-3'?getWord('ok'):getWord('submit')}}
                </mt-button>
                <mt-button class="foot_button1" type="primary" @click="canal()">{{ getWord('cancel') }}</mt-button>
            </div>
        </div>
    </section>
</template>
<style scoped lang='scss' style="text/css">
#withdraw-confirm {

    &.template-3 {        

        .myposition {
            z-index: 11;
        }        

        .main {
            border-radius: 3px;
            width: auto;
            height: auto;
            border:0;
            z-index: 12;
            right: 10%;
            background-color: #ffffff;

            .mint-header {
                font-size: .4rem;
                border-radius: 3px 3px 0 0;
                height: 1rem;
                background-color: #DB5C54;
            }

            .this_withdrawal {
                float: none;
                height: auto;
                width: auto;
                display: flex;
                padding: .25rem .5rem;
                justify-content: space-between;
                font-size: .3rem;
                border-color: #d0d0d0;

                .span_1 {
                    float: none;
                    width: auto;
                    height: auto;
                    line-height: 1;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding: .25rem;

                .mint-button {
                    width: auto;
                    height: auto;
                    margin: 0;                    
                    border-radius: 2px;
                    background-color:#DB5C54;
                    padding: .2rem .5rem;
                    width: 46%;
                    margin-top: .33rem;
                    font-size: .3rem;

                    &:last-child {
                        background-color:#EEEEEE;
                        color: #5F646E;
                    }
                }
            }
        }
    }

    .myposition {
        /*    //background-color:#D0D0D0;*/
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 888;
        background: #000;
        opacity: 0.5;
    }

    .main {
        position: absolute;
        width: 6.12rem;
        height: 6.69rem;
        border: 0.01rem solid rgba(255, 255, 255, 1);
        left: 10%;
        top: 20%;
        background-color: rgba(239, 239, 239, 1);
        z-index: 999;

        .mint-header {
            height:0.88rem;font-size: 0.35rem;
        }

        .this_withdrawal {
            float: left;
            height: 1.08rem;
            width: 100%;
            border-bottom: 1px solid rgba(187, 187, 187, 1);
        }

        .span_1 {
            float: left;
            width: 50%;
            height: 100%;
            line-height: 1.08rem;
            text-align: center;
        }

        .foot_button {
            width: 5.19rem;
            height: 0.68rem;
            margin: 0.6rem 0 0 0.46rem;
        }

        .foot_button1 {
            width: 5.19rem;
            height: 0.68rem;
            margin: 0.2rem 0 0 0.46rem;
        }
    }
}

</style>
